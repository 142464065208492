import React, { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import format from 'date-fns/format';

import { GLOBAL_CONSTANTS } from 'utils/constants';

import useStyles from './styles';

const VacancyTile = ({
  handleAdClick,
  data: {
    city,
    title,
    work_type,
    promotion,
    reference_id,
    date_modified,
    title_slug: slug,
    category_id: categoryId,
    profile_name: profileName,
    profile_type: profileType,
  },
}) => {
  const t = useTranslations();
  const styles = useStyles();

  const getPromotion = useMemo(
    () =>
      promotion ? (
        <div className="promotion-icon">
          {promotion === 'silver' ? (
            <img src="/images/icons/silver_icon.svg" alt="gold" />
          ) : promotion === 'gold' ? (
            <img src="/images/icons/gold_icon.svg" alt="gold" />
          ) : (
            ''
          )}
        </div>
      ) : null,
    [promotion],
  );

  const { value: categoryName } = GLOBAL_CONSTANTS.categories.find(
    el => el.id === categoryId,
  );

  return (
    <div onClick={() => handleAdClick?.(reference_id)}>
      <Link href={`/${slug}`}>
        <a>
          <span className={`${styles['tile__block']}`}>
            {getPromotion}
            <div className={styles['details--block']}>
              <div>
                <p className="sub-title-content">
                  {profileName}{' '}
                  {`${
                    profileType === 'couple'
                      ? t('are_looking_for')
                      : t('is_looking_for')
                  }`}
                </p>
                <h4 className="title-content">{title}</h4>
                <div className="bottom-info--block">
                  <p>
                    <span className="icon-Tag"></span>
                    {categoryName && t(categoryName)}
                  </p>
                  <p>
                    <span className="icon-Locatie"></span>
                    {city}
                  </p>
                  <p className="sub-title-content">{work_type}</p>
                  <p className="sub-title-content">{`${t(
                    'last_update_vacancy',
                  )} - ${format(new Date(date_modified), 'yyyy-MM-dd')}`}</p>
                </div>
              </div>
            </div>
          </span>
        </a>
      </Link>
    </div>
  );
};

export default React.memo(VacancyTile);

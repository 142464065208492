import useMount from 'hooks/use-mount';

import useStyles from './styles';

const bannerKeys = {
  '970x90': '44eb12802ecaac292d87bf5c462c2636',
  '300x100': '08afa3d1b4d284aa26c1e05db80f0298',
  '728x90': '1863a9b5b76f545f6f067158e7e17efc',
  '160x600': 'c04d966a27d8ecaa24140f3fe88c12b6',
  // '160x600': '08afa3d1b4d284aa26c1e05db80f0298',
};

const scriptSrc = 'https://aj1716.online/711145c5.js';

const Banner = ({ size }) => {
  const { root } = useStyles();

  useMount(() => {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.async = true;
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  });

  return (
    <div className={root}>
      <ins className="1ed70295" data-key={bannerKeys[size]} />
    </div>
  );
};

export default Banner;

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    margin: '10px 0',
    '& ins > ins': {
      width: '100% !important',
      display: 'flex !important',
      justifyContent: 'center !important',
    },
  },
});

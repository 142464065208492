import variables from 'static/styles/jss/abstracts/variables';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'slider-container': {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    '& .sj-image-tile--slider_prev-btn, & .sj-image-tile--slider_next-btn': {
      position: 'absolute',
      bottom: '0',
      background: variables.$baseColorDark_30,
      color: '#fff',
      padding: '3px',
      cursor: 'pointer',
      '&[disabled]': {
        background: 'rgb(52 61 77)',
        color: 'rgb(192 192 192)',
        cursor: 'default',
      },
    },

    '& .sj-image-tile--slider_next-btn': {
      left: '21px',
    },
    '& .slider_badge_row': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      transition: '.4s',
      transform: props => props.translateX,
      '& > img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        flexShrink: '0',
      },
    },
    '& .event_slider_badge_row': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      transition: '.4s',
      transform: props => props.translateX,
      '& > img': {
        width: '350px',
        height: '207px',
        objectFit: 'fill',
        flexShrink: '0',
      },
    },
  },
});

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  breadcrumb: {
    listStyleType: 'none',
    padding: '0',
    margin: '0 0 15px 0',
    display: 'flex',
    '& li': {
      flex: '1',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 'fit-content',
      color: '#fff',
      '&::before': {
        content: '"\\203A"',
        fontSize: '12px',
        margin: '0 5px',
        color: '#fff',
      },
      '&:first-child': {
        '&::before': {
          display: 'none',
        },
      },
      '&:last-child': {
        '& span': {
          cursor: ({ page }) => (page === 'promote' ? 'pointer' : 'text'),
        },
      },
      '& span': {
        textTransform: 'capitalize',
        color: '#fff !important',
        cursor: 'pointer',
        fontSize: '13px',
      },
    },
  },
});

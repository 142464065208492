import { memo } from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const MySJSimpleTitleBar = props => {
  const styles = useStyles();

  return (
    <div className={styles['simple-bar-container']} style={{ ...props.style }}>
      {props.children}
    </div>
  );
};
MySJSimpleTitleBar.defaultProps = {
  style: {},
};
MySJSimpleTitleBar.propTypes = {
  style: PropTypes.object,
};
export default memo(MySJSimpleTitleBar);

import { makeStyles } from '@material-ui/core/styles';
import {
  MediaQuery,
  Shadow,
  TextEllipsis,
  TextEllipsisLineClamp,
} from 'static/styles/jss/abstracts/mixins';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  tile__block: {
    borderRadius: '3px',
    display: 'block',
    cursor: 'pointer',
    backgroundColor: variables.$filters.$headerBG,
    ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    marginBottom: '16px',
    position: 'relative',
    '& .promotion-icon': {
      zIndex: 2,
      position: 'absolute',
      width: '20px',
      height: '20px',
      top: '0',
      display: 'flex',
    },
  },
  'details--block': {
    padding: '25px 10px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...MediaQuery.down({
      paddingRight: '20px',
    }).lg,
    '& .sub-title-content': {
      height: '30px',
      marginBottom: '12px',
      lineHeight: '15px',
      ...MediaQuery.down({
        fontSize: '13px !important',
      }).xs,
    },
    '& .title-content': {
      marginBottom: '12px',
      height: '33px',
      ...TextEllipsisLineClamp('14px !important', 2, 33, '16px'),
      width: '100%',
    },
    '& .bottom-info--block': {
      borderTop: `1px solid ${variables.$dividerColor}`,
      paddingTop: '10px',
      ...MediaQuery.down({
        flexWrap: 'wrap',
      }).sm,
      '& p': {
        margin: '0 0 10px',
        color: variables.$greyTxtColor,
        fontSize: '13px',
        lineHeight: '15px',
        ...TextEllipsis(),
        '& span': {
          marginRight: '6px',
        },
        '&:last-child': {
          marginBottom: '0',
        },
      },
    },
  },
});

import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'simple-bar-container': {
    border: '1px solid #003271',
    backgroundColor: variables.$extensionColor,
    minHeight: variables.$titleBarHeight,
    marginBottom: '16px',
    '& .title-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '0 !important',
      '&:hover': {
        color: '#fff',
      },
    },
    '& .simple-bar-flexible--block': {
      display: 'flex',
      margin: '0',
      fontSize: '16px',
      alignItems: 'center',
    },
    '& .simple-bar-flexible-info--block': {
      color: '#C5C5C5',
      marginBottom: 0,
      fontSize: '16px',
      '& span': {
        fontSize: '14px',
        marginRight: '10px',
      },
    },
    '& .simple-bar-flexible-info--block-divider': {
      position: 'relative',
      top: '-1px',
      margin: '0 10px',
      fontSize: '16px !important',
    },
    '& .simple-bar_title': {
      height: variables.$sxjBarHeight,
      padding: '0 16px',
      display: 'flex',
      alignItems: 'center',
      ...MediaQuery.down({
        padding: '0 16px',
      }).sm,
    },
  },
});
